.fuel-tank {
    display: inline-block;
    text-align: center;
    position: relative; /* Add this line to make it a relative container */
  }
  
  .tank {
    width: 110px;
    height: 130px;
    background-color: #ccc;
    position: relative;
    border: 2px solid #333;
    border-radius: 10px;
    margin: 10px;
    overflow: hidden;
  }
  
  .water {
    background-color: #0077cc;
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
  }
  
  .wave {
    position: absolute;
    width: 100%;
    height: 40px;
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #0077cc 10px, #0077cc 20px);
    animation: wave-animation 2s linear infinite;
  }
  
  .level-text {
    margin-top: 10px;
    font-weight: bold;
    color: #333;
  }
  
  /* Add styles for the water bubbles container */
  .water-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevents interaction with the GIF */
    z-index: 1; /* Positions the GIF above the blue-filled area */
  }
  
  .water-bubbles img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the GIF covers the entire area */
  }
  